import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './css/Internal.css'
import Popup from '../components/Popup'
import Cookies from 'js-cookie'
import axios from 'axios'
import { isAuthenticated, logout } from '../Auth'
import HoverMessage from '../components/HoverMessage'
import StyleRenderer from '../components/StyleRenderer'


function Internal(props) {
  const store = props['store']
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [userData, setUserData] = useState({})

  const [notice, setNotice] = useState([])
  const [viewingNotice, setViewingNotice] = useState(
    sessionStorage.getItem('viewingNotice') == 'true'
  )
  const noticeElements = []

  const [viewingAccount, setViewingAccount] = useState(false)

  const [changingPassword, setChangingPassword] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  const [customToolsList, setCustomToolsList] = useState([])
  const [customToolGroups, setCustomToolGroups] = useState({})
  const customToolsTree = {
    'nogroup': []
  }
  const customToolsElements = [
    <div className='items'>{customToolsTree['nogroup']}</div>
  ]

  const hours = new Date().getHours()

  const passwordChange = (e) => {
    e.preventDefault()
    const password = document.querySelector('#change-password-form div #password').value
    if (password == '') {
      setPasswordError(true)
      setPasswordMessage(t('password-empty'))
      return
    }
    if (password !== document.querySelector('#change-password-form div #confirm_password').value) {
      setPasswordError(true)
      setPasswordMessage(t('password-match'))
      return
    }
    setPasswordError(false)
    setPasswordMessage('Submitting...')
    axios.post(
      '/api/change_password',
      { 'password': password },
      {
        baseURL: '/',
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('accessToken')
        }
      }
    ).then((response) => {
      setPasswordMessage(t('password-success'))
      setTimeout(() => {
        logout(store['setLoggedIn'])
        navigate('/login')
      }, 2000)
    }).catch((err) => {
      setPasswordError(true)
      setPasswordMessage(err.response.data.errors)
    })
  }

  useEffect(() => {
    // get custom internal actions
    axios.get('/api/tools', {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setCustomToolsList(response.data)
    }).catch((err) => {
      if (err.response.status == 401 || err.response.status == 403) {
        window.location.reload(false)
      } else {
        console.log(err)
      }
    })

    // get custom internal action gorups
    axios.get('/api/tool_groups', {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      const data = {}
      response.data.forEach((group) => {
        data[group.id] = group.name
      })
      setCustomToolGroups(data)
    })

    // get notice
    axios.get('/api/notice', {
      baseURL: '/',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setNotice(response.data)
    })

    // get current user information
    if (!sessionStorage.getItem('userData.first_name')) {
      axios.get('/api/get_me', {
        baseURL: '/',
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('accessToken')
        }
      }).then((response) => {
        setUserData(response.data)
        for (const [key, value] of Object.entries(response.data)) {
          sessionStorage.setItem('userData.' + key, value)
        }
      })
    } else {
      setUserData({
        username: sessionStorage.getItem('userData.username'),
        first_name: sessionStorage.getItem('userData.first_name'),
        last_name: sessionStorage.getItem('userData.last_name'),
        email: sessionStorage.getItem('userData.email'),
        is_staff: sessionStorage.getItem('userData.is_staff') == 'true',
        date_joined: sessionStorage.getItem('userData.date_joined'),
      })
    }
  }, [])


  for (const group in customToolGroups) {
    customToolsTree[group] = []
    customToolsElements.push(<>
      <h2>{customToolGroups[group]}</h2>
      <div className='items'>{customToolsTree[group]}</div>
    </>)
  }


  for (const tool of customToolsList) {
    try {
      customToolsTree[tool.group || 'nogroup'].push(
        <InternalAction href={tool.link} message={tool.description} label={tool.label} f={() => {
          window.open(tool.link, '_blank')
        }} style={{ background: tool.bg_color, color: tool.font_color }} />
      )
    } catch {

    }
  }

  for (const item of notice) {
    noticeElements.push(
      <div className={viewingNotice ? 'notice-item' : 'notice-item hidden'}>
        <h3>{item.title}</h3>
        <h6>{t('date') + ': ' + new Date(item.date).toLocaleString()}</h6>
        <h6>{t('last-edit') + ': ' + new Date(item.last_edit).toLocaleString()}</h6>
        <StyleRenderer text={item.content} />
      </div>
    )
  }


  useEffect(() => {
    setTimeout(() => {
      setPasswordMessage('')
      setPasswordError(false)
    }, 7000)
  }, [passwordMessage])

  useEffect(() => {
    setPasswordMessage('')
    setPasswordError(false)
  }, [changingPassword])
  return (
    <div id='main-content'>
      <div id='internal-container'>
        <div className='banner'>
          <h1>{
            (hours < 12) && (hours > 3) ? t('good-morning') : (hours < 18) && (hours > 3) ? t('good-afternoon') : t('good-evening')
          }<span>{userData['first_name']} {userData['last_name']}</span>!</h1>
        </div>
        <div className='action-section' id='internal-notice' style={{ animationDelay: '0ms' }}>
          <div id='notice-view-arrow' className={viewingNotice ? 'active' : ''} onClick={() => {
            setViewingNotice(!viewingNotice)
            sessionStorage.setItem('viewingNotice', !viewingNotice)
          }}>
          </div>
          <h1 onClick={() => {
            document.querySelector('#notice-view-arrow').click()
          }}>{t('notices')}</h1>
          <h4 id='notice-count' onClick={() => {
            document.querySelector('#notice-view-arrow').click()
          }}>[ {noticeElements.length} ]</h4>
          {noticeElements}
        </div>
        <div className='action-section' id='internal-account' style={{ animationDelay: '200ms' }}>
          <h1>{t('account')}</h1>
          <div className='items'>
            <InternalAction label={t('account-info')} f={() => { setViewingAccount(!viewingAccount) }} />
            <InternalAction label={t('change-password')} f={() => { setChangingPassword(!changingPassword) }} />
            {viewingAccount ? <Popup posX={0} posY={0} state={viewingAccount} setState={setViewingAccount} content={userData ? (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: '1vh', }}>
                <h2 style={{ gridColumn: '1/3' }}>{t('account-info')}</h2>
                <h3>{t('username')}:</h3>
                <h3>{userData['username']}</h3>
                <h3>{t('first_name')}:</h3>
                <h3>{userData['first_name']}</h3>
                <h3>{t('last_name')}:</h3>
                <h3>{userData['last_name']}</h3>
                <h3>{t('email')}:</h3>
                <h3>{userData['email']}</h3>
                <h3>{t('date_joined')}:</h3>
                <h3>{(new Date(userData['date_joined'])).toLocaleString()}</h3>
              </div>
            ) : null} /> : null}
            {changingPassword ? <Popup posX={0} posY={0} state={changingPassword} setState={setChangingPassword} content={(
              <div>
                <form style={{ width: '40vw' }} id='change-password-form' method='post' onSubmit={passwordChange}>
                  <h1 style={{ background: (passwordError ? 'red' : 'white'), color: (passwordError ? 'white' : 'black'), margin: '1vh 0', padding: '1vh', fontSize: '22px', whiteSpace: 'pre-line' }}>{passwordMessage ? passwordMessage : t('change-password')}</h1>
                  <div>
                    <label htmlFor='password'>{t('password')}:</label>
                    <input id='password' type='password' />
                  </div>
                  <div>
                    <label htmlFor='confirm_password'>{t('confirm_password')}:</label>
                    <input id='confirm_password' type='password' />
                  </div>
                  <button type='submit' form='change-password-form'>{t('confirm')}</button>
                </form>
              </div>
            )} /> : null}
          </div>
        </div>
        {userData['is_staff'] ? <div className='action-section' id='internal-admin' style={{ animationDelay: '400ms' }}>
          <h1>{t('admin')}</h1>
          <div className='items'>
            <InternalAction href='/internal/partners' label={t('partners-management')} f={() => {
              navigate('/internal/partners')
            }} />
            <InternalAction href='/internal/products' label={t('products-management')} f={() => {
              navigate('/internal/products')
            }} />
          </div>
          <div className='items'>
            <InternalAction href='/internal/notice-management' label={t('notice-management')} f={() => {
              navigate('/internal/notice-management')
            }} />
            <InternalAction href='/internal/users' label={t('account-management')} f={() => {
              navigate('/internal/users')
            }} />
            <InternalAction href='/internal/translations' label={t('translations')} f={() => {
              navigate('/internal/translations')
            }} />
            <InternalAction href='/internal/tool-management' label={t('tool-management')} f={() => {
              navigate('/internal/tool-management')
            }} />
          </div>
        </div> : null}
        <div className='action-section' id='internal-tools' style={{ animationDelay: '600ms' }}>
          <h1>{t('tools')}</h1>
          <div className='items'>
            <InternalAction href='/internal/send_email' label={t('send-email')} f={() => {
              navigate('/internal/send_email')
            }} />
            <InternalAction href='/internal/qr_code' label={t('qr-generator')} f={() => {
              navigate('/internal/qr_code')
            }} />
            <InternalAction href='/internal/contacts' label={t('contact-requests')} f={() => {
              navigate('/internal/contacts')
            }} />
          </div>
          {customToolsElements}
        </div>
      </div>
    </div >
  )
}

function InternalAction(props) {
  const [hovering, setHovering] = useState(false)
  return (
    <div className='action' onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} onClick={props['f']} style={{ ...props['style'], position: 'relative' }}>
      {props['label']}
      {props.href ? <a onClick={(e) => { e.preventDefault() }} href={props.href}></a> : null}
      {props['message'] ? <HoverMessage state={hovering} message={props['message']} /> : null}
    </div >
  )
}

export default Internal
