import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import axios from 'axios'
import './css/Contact.css'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

function Contact() {
  const [submitted, setSubmitted] = useState(false)
  const [message, setMessage] = useState('')
  const [captchaToken, setCaptchaToken] = useState('')

  const { t } = useTranslation('translation', { keyPrefix: 'contact' })
  const formSubmit = (e) => {
    if (submitted) {
      return
    }
    e.preventDefault()
    document.querySelectorAll('input').forEach((element) => {
      if ((!element.value) && (element.type !== 'hidden')) {
        console.log(element)
        setMessage('Please fill in all the fields.')
        throw 'Please fill in all the fields.'
      }
    })
    const form = new FormData(e.target)
    form.append('captcha', captchaToken)
    setMessage(t('form-submitting'))
    axios.post('/api/contact', form, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.status === 201) {
        setSubmitted(true)
        setMessage(t('form-thanks'))
      }
    }).catch((err) => {
      setMessage(err.response.data.errors)
    })

  }

  useEffect(() => {
    setTimeout(() => {
      setMessage('')
    }, 7000)
  }, [message])

  return (
    <div id='main-content'>
      <div id='contact-container'>
        <div id='info'>
          <h1>{t('info-title')}</h1>
          <h2>{t('info-demo')}</h2>
          <a href='https://goo.gl/maps/AHCJZxDN2LU9Mw7R7' id='address'>{t('info-address')}</a>
          <br />
          <a href='tel:+85228166615' id='telephone'>+852 2816 6615</a>
        </div>
        <form id='contact-form' action='/api/contact' method='post' onSubmit={formSubmit}>
          <h1>{message ? (submitted ? t('form-thanks') : message) : t('form-title')}</h1>
          <div>
            <label htmlFor="name">{t('form-name')}: </label>
            <input id="name" type="text" name="name" />
          </div>
          <div>
            <label htmlFor="phone_number">{t('form-phone')}: </label>
            <input id="phone_number" type="text" name="phone_number" />
          </div>
          <div>
            <label htmlFor="email">{t('form-email')}: </label>
            <input id="email" type="text" name="email" />
          </div>
          <div>
            <label htmlFor="message">{t('form-message')}: </label>
            <input id="message" type="text" name="message" />
          </div>
          <input type='hidden' name='csrfmiddlewaretoken' value={Cookies.get('csrftoken')} />
          <button type="submit" form="contact-form" value="Submit">{t('form-submit')}</button>
        </form>
      </div>
      <GoogleReCaptcha onVerify={
        setCaptchaToken
      } />
    </div>
  )
}

export default Contact
