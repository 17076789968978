import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Cookies from 'js-cookie'
import axios from "axios";
import './css/Admin.css'

function Admin(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const navigate = useNavigate()
  const [result, setResult] = useState(null)

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='admin-container'>
        <div id='admin-panel'>
          <div id='admin-actions'>
            <Button label='rebuild' f={() => {
              setResult(
                <p>rebuilding react...<br />page will be unavailable for approx. 1 minute</p>
              )
              axios.get('/api/rebuild', {
                baseURL: '/',
                headers: {
                  Authorization: 'Bearer ' + Cookies.get('accessToken')
                }
              }).then((response) => {
                setResult(
                  <p style={{ background: 'green', color: 'white' }}>rebuild complete. refreshing in 5 seconds...</p>
                )
                setTimeout(() => {
                  window.location.reload(false)
                }, 5000)
              })
            }} />
            <Button label='browse uploads' f={() => {
              axios.get('/api/browse_uploads', {
                baseURL: '/',
                headers: {
                  Authorization: 'Bearer ' + Cookies.get('accessToken')
                }
              }).then((response) => {
                setResult(
                  <p>files:<br /> {(',' + response.data['files'].toString()).replaceAll(',', '\n\t')}</p>
                )
              })
            }} />
            <Button label='clean uploads' f={() => {
              axios.get('/api/clean_uploads', {
                baseURL: '/',
                headers: {
                  Authorization: 'Bearer ' + Cookies.get('accessToken')
                }
              }).then((response) => {
                setResult(
                  <p>deleted files:<br /> {(',' + response.data['deleted_files'].toString()).replaceAll(',', '\n\t')}</p>
                )
              })
            }} />
            <Button label='get logs' f={() => {
              axios.get('/api/logs', {
                baseURL: '/',
                headers: {
                  Authorization: 'Bearer ' + Cookies.get('accessToken')
                }
              }).then((response) => {
                const elements = []

                for (const line of response.data) {
                  if (line.includes('[ ERROR ]')) {
                    elements.push(
                      <span className="error">{line}</span>
                    )
                  } else if (line.includes('[ WARNING ]')) {
                    elements.push(
                      <span className="warning">{line}</span>
                    )
                  } else if (line.includes('HTTP')) {
                    elements.push(
                      <span className="http">{line}</span>
                    )
                  } else {
                    elements.push(line)
                  }
                }
                setResult(
                  <p>{elements}</p>
                )
              })
            }} />
          </div>
          <div id='admin-result'>
            <h3>RESULT:</h3>
            {result}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin
