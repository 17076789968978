import React, { useEffect, useState } from "react";

import './css/ProductManagement.css'
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'
import Popup from "../components/Popup";

function ProductManagement() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [products, setProducts] = useState({})
  const [categories, setCategories] = useState({})

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0)
  const [categoryList, setCategoryList] = useState([])
  const [currentCategory, setCurrentCategory] = useState('')

  const [addingCategory, setAddingCategory] = useState(false)

  const productTree = {}
  const categoryViews = []

  useEffect(() => {
    axios.get('/api/products', {
      headers: {
        Authorization: "Bearer " + Cookies.get('accessToken')
      }
    }).then((response1) => {
      setProducts(response1.data)
      axios.get('/api/product_categories', {
        headers: {
          Authorization: "Bearer " + Cookies.get('accessToken')
        }
      }).then((response2) => {
        const data = {}
        const list = []
        response2.data.forEach((item) => {
          data[item['id']] = item['name']
          list.push(item['name'])
        })
        setCategories(data)
        setCategoryList(list)
        setCurrentCategoryIndex(list.indexOf(searchParams.get('target')) === -1 ? 0 : list.indexOf(searchParams.get('target')))
        setCurrentCategory(searchParams.get('target') || list[0])
      })
    }).catch((err) => {
      window.location.reload(false)
    })
  }, [])

  useEffect(() => {
    setCurrentCategory(categoryList[Math.abs(currentCategoryIndex)])
  }, [currentCategoryIndex])
  useEffect(() => {
    setSearchParams({ target: currentCategory || categoryList[0] })
  }, [currentCategory])

  for (const category in categories) {
    productTree[category] = []
  }
  try {
    for (const product in products) {
      productTree[products[product]['category']].push(products[product])
    }
  } catch {

  }

  for (const category in productTree) {
    categoryViews.push(
      <CategoryView categoryId={category} category={categories[category]} products={productTree[category]} currentCategory={currentCategory} />
    )
  }

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('translation:internal.back')} />
      <div id='product-management-container'>
        <div id='product-management'>
          <p id='product-management-info'>{t('internal.product-management-info')}</p>
          <div style={{ padding: '3vh', background: 'white' }}>
            <Button label={'+ ' + t('internal.new-category')} f={() => {
              setAddingCategory(true)
              setTimeout(() => {
                document.getElementById('category-new').focus()
              }, 0)
            }} />
            {addingCategory ?
              <div style={{ display: 'flex', flexDirection: 'row', margin: '1vh 0', gap: '1vh' }}>
                <input id='category-new' style={{ padding: '1vh', width: '100%', border: '0', borderRadius: '0', borderBottom: '2px solid black', outline: 'none', fontSize: '16px' }} type='text' placeholder={t('internal.name')} />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button label={t('internal.confirm')} f={() => {
                    const value = document.querySelector('#category-new').value
                    if (!value) return
                    axios.post('/api/product_categories', {
                      name: value.replaceAll(' ', '_').replaceAll('.', '_')
                    }, {
                      baseURL: '',
                      headers: {
                        Authorization: 'Bearer ' + Cookies.get('accessToken')
                      }
                    }).then((response) => {
                      setSearchParams({ 'target': value })
                      window.location.reload(false)
                    })
                  }} />
                  <Button label={t('internal.cancel')} f={() => {
                    setAddingCategory(false)
                  }} />
                </div>
              </div>
              : null}
          </div>
          <div id='category-nav'>
            <Button f={() => {
              setCurrentCategoryIndex((currentCategoryIndex - 1) % categoryList.length)
            }} label='<' />
            <Button f={() => {
              setCurrentCategoryIndex((currentCategoryIndex + 1) % categoryList.length)
            }} label='>' />
          </div>
          <div id='categories'>
            {categoryViews}
          </div>
        </div>
      </div>
    </div>
  )
}

function CategoryView({ products, category, currentCategory, categoryId }) {
  const { t, i18n } = useTranslation()
  const [addingProduct, setAddingProduct] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const translatedTitles = []
  for (const lang of i18n.languages) {
    translatedTitles.push(
      t(`products:${category}._name`, { lng: lang })
    )
  }

  const elements = []
  for (const product of products) {
    elements.push(
      <Product category={category} id={product.id} name={product.name} monthlyPrice={product['monthly_price']} upfrontPrice={product['upfront_price']} />
    )
  }

  return (
    <>
      {deleting ?
        <Popup state={deleting} setState={setDeleting} content={
          <>
            <h3 style={{ marginTop: '3vh' }}>{t('internal.confirm-delete') + '"' + category + '" ?'}</h3>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1vh', marginTop: '1vh' }}>
              <Button label={t('confirm')} f={() => {
                axios.delete('/api/product_categories', {
                  params: {
                    id: categoryId
                  },
                  baseURL: '',
                  headers: {
                    Authorization: "Bearer " + Cookies.get('accessToken')
                  }
                }).then((response) => {
                  window.location.reload(false)
                })
              }} />
              <Button label={t('cancel')} f={() => {
                setDeleting(false)
              }} />
            </div>
          </>
        } />
        : null}
      <div id={category} className={currentCategory === category ? "category-view active" : "category-view"}>
        <h1 className="category-title">{translatedTitles.join(' / ')}</h1>
        <div className="buttons">
          <Button label={'+ ' + t('internal.new-product')} f={() => {
            setAddingProduct(true)
            setTimeout(() => {
              document.getElementById('name-new').focus()
            }, 0)
          }} />
          <Button label={t('internal.delete-category')} f={() => {
            setDeleting(true)
          }} />
        </div>
        <h2>{t('internal.name')}:</h2>
        <h2>{t('internal.monthly-price')}:</h2>
        <h2>{t('internal.upfront-price')}:</h2>
        <h2></h2>
        {elements}
        {addingProduct ?
          <>
            <input id='name-new' placeholder={t('internal.name')} type='text' />
            <input min={0} step={100} id='monthly-price-new' placeholder={t('internal.monthly-price')} type='number' />
            <input min={0} step={100} id='upfront-price-new' placeholder={t('internal.upfront-price')} type='number' />
            <div className="buttons">
              <Button label={t('internal.confirm')} f={() => {
                const name = document.querySelector('#name-new').value
                const mp = parseInt(document.querySelector('#monthly-price-new').value) | 0
                const up = parseInt(document.querySelector('#upfront-price-new').value) | 0

                if (!name) {
                  return
                }
                axios.post('/api/products', {
                  name: name.replaceAll(' ', '_').replaceAll('.', '_'),
                  monthly_price: mp,
                  upfront_price: up,
                  category: categoryId,
                  show_in_quotation: false
                }, {
                  baseURL: '',
                  headers: {
                    Authorization: 'Bearer ' + Cookies.get('accessToken')
                  }
                }).then((response) => {
                  window.location.reload(false)
                })
              }} />
              <Button label={t('internal.cancel')} f={() => {
                setAddingProduct(false)
              }} />
            </div>
          </>
          : null}
      </div>
    </>
  )
}

function Product({ category, id, name, monthlyPrice, upfrontPrice }) {
  const { t, i18n } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [hovering, setHovering] = useState(false)

  const translations = []

  for (const lang of i18n.languages) {
    translations.push(
      <h3 style={{ fontStyle: 'italic', fontSize: '12px' }}>{lang}: {t(`products:${category}.${name}`, { lng: lang })}</h3>
    )
    translations.push(
      <p style={{ fontSize: '12px', gridColumn: '2/5', textAlign: 'left', margin: '0', fontStyle: 'italic' }}>{t(`products:${category}.${name}-desc`, { lng: lang })}</p>
    )
  }

  return (
    <>
      <h3 onMouseEnter={() => {
        setHovering(true)
      }} onMouseLeave={() => {
        setHovering(false)
      }
      }>{name}</h3>
      {editing ?
        <>
          <input min={0} step={100} id={id + '-' + name + '-mp'} defaultValue={monthlyPrice} />
          <input min={0} step={100} id={id + '-' + name + '-up'} defaultValue={upfrontPrice} />
          <div className="buttons">
            <Button label={t('translation:internal.save')} f={() => {
              axios.put('/api/products', {
                monthly_price: document.getElementById(`${id}-${name}-mp`).value || monthlyPrice,
                upfront_price: document.getElementById(`${id}-${name}-up`).value || upfrontPrice
              }, {
                params: {
                  id: id
                },
                baseURL: '',
                headers: {
                  Authorization: "Bearer " + Cookies.get('accessToken')
                }
              }).then((response) => {
                window.location.reload(false)
              })
            }} />
            <Button label={t('translation:internal.cancel')} f={() => {
              setEditing(false)
            }} />
          </div>
        </> :
        <>
          <h3 className="price">{monthlyPrice}</h3>
          <h3 className="price">{upfrontPrice}</h3>
          <div className="buttons">
            <Button label={t('translation:internal.edit')} f={() => {
              setEditing(true)
            }} />
            <Button label={t('translation:internal.delete')} f={() => {
              axios.delete('/api/products', {
                baseURL: '',
                params: {
                  id: id
                },
                headers: {
                  Authorization: "Bearer " + Cookies.get('accessToken')
                }
              }).then((response) => {
                window.location.reload(false)
              })
            }} />
          </div>
        </>}

      {hovering ? <>
        {translations}
      </> : null}
    </>
  )
}

export default ProductManagement
