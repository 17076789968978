import React from 'react'
import './css/Button.css'

function Button({ f, id, label }) {
  return (
    <div id={id} className='button' onClick={f}>
      {label}
    </div>
  )
}

export default Button
