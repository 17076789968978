import React from 'react'
import { useTranslation } from 'react-i18next'
import './css/Home.css'
import MiniSlideshow from '../components/MiniSlideshow'
import { Parallax } from 'react-scroll-parallax'
import { useInView } from 'react-intersection-observer'

function Home() {
  const { t } = useTranslation('translation', { keyPrefix: 'home' })

  const inViewOptions = {
    threshold: 0
  }
  const [ref1, inView1] = useInView(inViewOptions)
  const [ref2, inView2] = useInView(inViewOptions)

  var missionElements = []
  t('mission-list').split('\\').forEach((item) => {
    missionElements.push(<li>{item}</li>)
  })

  return (
    <div id='main-content'>
      <div id='home-container'>
        <Parallax style={
          {
            width: '100%',
          }
        } speed={-40}>
          <div className='hero'>
            <h1>F&B For <span style={{ color: 'var(--orange)', fontWeight: '900' }}>Me</span></h1>
            <div id='hero-logo'>
              <img src={require('../img/logo.png')} />
            </div>
          </div>
        </Parallax>
        <div ref={ref1} className={inView1 ? 'section' : 'section hidden'}>
          <h1>{t('about-us')}</h1>
          <p>{t('about-us-content')}</p>
          <div id='products'>
            <MiniSlideshow pics={[
              require('../img/products/pos.jpeg'),
              require('../img/products/cctv.png'),
              require('../img/products/payment.jpg'),
              require('../img/products/network.jpeg'),
            ]} />
            <ul className='list'>
              <li>{t('products-pos')}</li>
              <li>{t('products-cctv')}</li>
              <li>{t('products-payment')}</li>
              <li>{t('products-network')}</li>
            </ul>
          </div>
        </div>
        <div ref={ref2} className={inView2 ? 'section' : 'section hidden'}>
          <h1>{t('about-title')}</h1>
          <h2>{t('about-statement')}</h2>
          <h3>{t('brand-title')}:</h3>
          <p>{t('brand-content')}</p>
          <h3>{t('mission-title')}:</h3>
          <ul className='list'>
            {missionElements}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Home
