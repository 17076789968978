import React, { useEffect, useState } from 'react'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from "react-router-dom";
import './css/Header.css'
import { isAuthenticated, logout } from '../Auth'
import Button from './Button'
import Burger from './Burger'

var logo = require('../img/logo.png')

function Header(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'nav-bar' })
  const navigate = useNavigate()

  const store = props['store']
  const [loggedIn, setLoggedIn] = store['loggedIn']

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const [scrollPos, setScrollPos] = useState(0)

  useEffect(
    () => {
      setLoggedIn(isAuthenticated())
      window.addEventListener('scroll', () => {
        setScrollPos(window.scrollY)
      }, { passive: true })
      window.addEventListener('resize', () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
      })
    }, []
  )
  return (
    <header>
      <div id='header'>
        <div id='logo-container'>
          <img id='logo' src={logo} />
        </div>
        {windowWidth > 800 ? (
          <div id='header-items'>
            <div id='nav-links'>
              <Link to='/'>{t('home')}</Link>
              <Link to='/services'>{t('services')}</Link>
              <Link to='/partners'>{t('partners')}</Link>
              <Link to='/contact'>{t('contact')}</Link>
            </div>
            {loggedIn ? (window.location.pathname.includes('internal') ? <Button id={null} f={() => { logout(); setLoggedIn(false); navigate('/') }} label={t('logout')} /> : <Button id={null} f={() => { navigate('/internal') }} label={t('internal')} />) : <Button id={null} f={() => { navigate('/login') }} label={t('login')} />}
            <LanguageSwitcher />
          </div>) : <Burger store={store} loggedIn={[loggedIn, setLoggedIn]} />
        }
      </div>
    </header>
  )
}

export default Header
