import React, { useEffect, useState, useRef } from 'react'
import { isAuthenticated } from '../Auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Button from '../components/Button'
import axios from 'axios'
import './css/Translations.css'
import Popup from '../components/Popup'

function Translations(props) {
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const { t } = useTranslation()

  const [namespaces, setNamespaces] = useState([])
  const [currentNamespaceIndex, setCurrentNamespaceIndex] = useState(0)
  const currentNamespace = namespaces[currentNamespaceIndex]

  const [message, setMessage] = useState('')

  useEffect(() => {
    axios.get('/api/translations', {
      headers: {
        'Authorization': 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setData(response.data)
      setNamespaces(Object.keys(response.data))
    }).catch((err) => {
      if (err.response.status == 401) {
        window.location.reload(false)
      } else {
        console.log(err)
      }
    })
  }, [])
  const translationDisplays = []
  for (const translationDisplay in data) {
    translationDisplays.push(
      <TranslationsDisplay langs={Object.keys(data[translationDisplay])} currentNamespace={currentNamespace} mainData={data} namespace={translationDisplay} />
    )
  }

  useEffect(() => {
    try {
      document.querySelector('#view-container').style = 'height: ' + document.querySelector('#view-container .translations.active').getBoundingClientRect()['height'] + "px"
    } catch {

    }
  }, [currentNamespaceIndex])

  const submitData = () => {
    const newData = global.structuredClone(data)
    for (const element of document.querySelectorAll('.translation-input')) {
      const [ns, lang, scope, key] = element.id.split('.')
      newData[ns][lang][scope][key] = element.value
    }
    axios.post('/api/translations', newData, {
      baseURL: '',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      window.location.reload(false)
    })
  }

  const submitWithRebuild = () => {
    const newData = global.structuredClone(data)
    for (const element of document.querySelectorAll('.translation-input')) {
      const [ns, lang, scope, key] = element.id.split('.')
      newData[ns][lang][scope][key] = element.value
    }
    axios.post('/api/translations', newData, {
      baseURL: '',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setMessage(t('internal.await-rebuild'))
      axios.get('/api/rebuild', {
        baseURL: '',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('accessToken')
        }
      }).then((response) => {
        setMessage(t('internal.rebuild-success'))
        setTimeout(() => {
          window.location.reload(false)
        }, 5000)
      })
    })
  }

  return (
    <div id='main-content' >
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('internal.back')} />
      <div id='translations-container'>
        <h2 id='translations-message'>{message ? message : t('internal.translations-info')}</h2>
        <div id='translations-actions'>
          <Button label={t('internal.save')} f={submitData} />
          <Button label={t('internal.rebuild')} f={submitWithRebuild} />
          <Button label={t('internal.reset')} f={() => {
            window.location.reload(false)
          }} />
        </div>
        <div id='translations-nav'>
          <Button f={() => {
            setCurrentNamespaceIndex(Math.abs(currentNamespaceIndex - 1) % namespaces.length)
          }} label='<' />
          <Button f={() => {
            setCurrentNamespaceIndex((currentNamespaceIndex + 1) % namespaces.length)
          }} label='>' />
        </div>
        <div id='view-container'>
          {translationDisplays}
        </div>
      </div>
    </div>
  )
}

function TranslationsDisplay({ langs, mainData, namespace, currentNamespace }) {
  const [data, setData] = useState(mainData[namespace])
  const [scopes, setScopes] = useState(Object.keys(data[langs[0]]))

  const [currentScope, setCurrentScope] = useState(scopes[0])

  const scopeElements = []
  const inputElements = []
  useEffect(() => {
    setData(mainData[namespace])
    setScopes(Object.keys(mainData[namespace][langs[0]]))
    document.querySelectorAll('#' + namespace + ' .display .scopes .scope').forEach((element) => {
      if (element.getAttribute('data-scope') === currentScope) {
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })
    document.querySelector(`#${namespace} .display .inputs-container`).style = 'min-height: ' + document.querySelector(`#${namespace} .display .inputs-container .inputs.active`).getBoundingClientRect()['height'] + 'px'

    setTimeout(() => {
      try {
        document.querySelector('#view-container').style = 'height: ' + document.querySelector('#view-container .translations.active').getBoundingClientRect()['height'] + "px"
      } catch {

      }
    }, 201)
  }, [currentScope])

  for (const scope of scopes) {
    scopeElements.push(
      <h2 className='scope' data-scope={scope} onClick={() => {
        setCurrentScope(scope)
      }}>{scope}</h2>
    )
  }

  try {
    for (const scope of scopes) {
      const l = {}
      for (const lang of langs) {
        l[lang] = (data[lang][scope])
      }
      inputElements.push(
        <ScopeView data={l} ns={namespace} currentScope={currentScope} scopeName={scope} />
      )
    }
  } catch {

  }

  return (
    <div id={namespace} className={currentNamespace == namespace ? 'translations active' : 'translations'}>
      <h3>{namespace}</h3>
      <div className='display'>
        <div className='scopes'>
          {scopeElements}
        </div>
        <div className='inputs-container'>
          {inputElements}
        </div>
      </div>
    </div>
  )
}

function ScopeView({ ns, data, currentScope, scopeName }) {
  const elements = []

  for (const key in data[Object.keys(data)[0]]) {
    elements.push(<h6>{key}</h6>)
    for (const lang in data) {
      elements.push(
        <input id={`${ns}.${lang}.${scopeName}.${key}`} className='translation-input' defaultValue={data[lang][key]} />
      )
    }
  }
  return (
    <div className={currentScope == scopeName ? 'inputs active' : 'inputs'}>
      {elements}
    </div>
  )
}

export default Translations
