import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'

import './css/SendEmail.css'
import axios from "axios";

function SendEmail(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const navigate = useNavigate()

  const [recipients, setRecipients] = useState([])
  const recipientElements = []

  const [message, setMessage] = useState(t('send-email'))

  recipients.forEach((item) => {
    if (item) {
      recipientElements.push(
        <div className={item.toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ? "recipient" : "recipient error"}> {item}</div>
      )
    }
  })

  const sendEmail = () => {
    for (const recipient of recipients) {
      if (!recipient.toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setMessage('"' + recipient + '" ' + t('invalid-email'))
        return
      }
    }
    const data = {
      recipients: recipients,
      subject: document.querySelector('#subject').value,
      content: document.querySelector('#content').innerHTML,
      sender: sessionStorage.getItem('userData.email')
    }
    if ((recipients.length === 0) || (!data.subject) || (!data.content)) {
      setMessage(t('fill-all-fields'))
      return
    }
    axios.post('/api/send_mail', data, {
      baseURL: '/',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setMessage(t('success'))
      setTimeout(() => {
        window.location.reload(false)
      }, 2000)
    })
  }

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='send-email-container'>
        <div id='send-email'>
          <h3>{message}</h3>
          <div id='recipient-list'>
            {recipientElements}
          </div>
          <div id='send-email-grid'>
            <label>{t('recipients')}</label>
            <input id='recipients' type='text' placeholder="user1@example.com user2@example.com" onChange={(e) => {
              setRecipients(
                e.target.value.split(' ')
              )
            }} />
            <label>{t('subject')}</label>
            <input id='subject' type='text' />
            <label>{t('content')}</label>
            <p id='content' contentEditable='plaintext-only'></p>
            <Button label={t('confirm')} f={sendEmail} id='send-button' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendEmail
