import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components/Button";

import './css/VerifyQuotation.css'

function VerifyQuotation(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'quotation' })
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [success, setSuccess] = useState(false)
  const [attempted, setAttempted] = useState(false)

  const quotationID = searchParams.get('id')
  const verificationKey = searchParams.get('key')

  useEffect(() => {
    axios.get('/api/quotation/verify', {
      baseURL: '',
      params: {
        id: quotationID,
        key: verificationKey
      }
    }).then((response) => {
      setAttempted(true)
      setSuccess(true)
    }).catch((err) => {
      setAttempted(true)
    })
  })

  return (
    <div id='main-content'>
      <div id='verify-quotation'>
        {success ?
          <>
            <h1>{t('verify-success')}</h1>
            <Link to={'/quotation/view?id=' + quotationID}>{t('view-quotation')}</Link>
          </>
          : attempted ?
            <h1>{t('verify-fail')}</h1>
            : <h1>{t('verifying')}</h1>}
      </div>
    </div>
  )
}

export default VerifyQuotation
