import React from 'react'
import './css/LanguageSwitcher.css'
import { useTranslation } from 'react-i18next'

function LanguageSwitcher() {
  const [open, setOpen] = React.useState(false)

  const { t } = useTranslation('translation', { keyPrefix: 'meta' })
  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div id='lang-switch-container'>
      <div id='lang-switch-btn' onClick={handleOpen}><p>{t('abr')}</p></div>
      {open ? (
        <div id='lang-menu'>
          <LanguageListItem lang='en' />
          <LanguageListItem lang='zh' />
        </div>
      ) : null}
    </div>
  )
}

function LanguageListItem(props) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'meta' })
  return (
    <div className='lang-item' onClick={() => {
      if (props['lang'] !== i18n.language) {
        i18n.changeLanguage(props['lang'])
        localStorage.setItem('language', props['lang'])
        window.dispatchEvent(new StorageEvent('storage', {
          key: 'language'
        }))
      }
    }}>
      {t('name', { lng: props['lang'] })}
    </div>
  )
}

export default LanguageSwitcher
