import React, { useEffect, useRef, useState } from "react";
import './css/Quotation.css'
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import axios from "axios";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";

function Quotation() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [quotationForm, setQuotationForm] = useState(null)
  const [captchaToken, setCaptchaToken] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [formData, setFormData] = useState({})

  const switchPage = (event) => {
    setCurrentPage(parseInt(event.target.getAttribute('data-page')))
  }

  const navElements = [
    <h3 data-page={0} onClick={switchPage}>{t('quotation.contact')}</h3>
  ]
  const pageElements = [
    <div data-page={0} className="page">
      <h1>{t('quotation.contact')}</h1>
      <div className="input-grid" style={{ gridTemplateColumns: '1fr 1fr' }}>
        <h6>{t('quotation.name')}</h6>
        <input id='contact-name' type='text' />
        <h6>{t('quotation.email')}</h6>
        <input id='contact-email' type='email' placeholder="name@example.com" />
        <h6>{t('quotation.phone')} <span>{t('quotation.optional')}</span></h6>
        <input id='contact-phone' type='text' />
        <br />
        <br />
        <h6 style={{ gridColumn: '1/3' }}>{t('quotation.restaurant-detail')}</h6>
        <p id='contact-detail' contentEditable='plaintext-only'></p>
      </div>
    </div>
  ]
  const confirmationPageElements = []

  useEffect(() => {
    axios.get('/api/quotation_form').then((response) => {
      setQuotationForm(response.data)
    })
  }, [])

  let index = 1
  for (const category in quotationForm) {
    navElements.push(
      <h3 data-page={index} onClick={switchPage}>{t('products:' + category + '._name')}</h3>
    )
    const inputs = []
    for (const [id, name, mp, up] of quotationForm[category]) {
      inputs.push(
        <InputItem id={id} name={name} mp={mp} up={up} category={category} />
      )
    }
    pageElements.push(
      <div data-page={index} className="page">
        <h1>{t('products:' + category + '._name')}</h1>
        <div className="input-grid">
          {inputs}
        </div>
      </div>
    )
    index++
  }

  for (const product in formData) {
    confirmationPageElements.push(
      <h6>{t('products:' + product)}</h6>,
      <h6>{formData[product][0]}</h6>
    )
  }

  navElements.push(
    <h3 data-page={index} onClick={switchPage}>{t('quotation.confirmation')}</h3>
  )

  pageElements.push(
    <div data-page={index} className="page">
      <h1>{t('quotation.confirmation')}</h1>
      <div className="input-grid" >
        {confirmationPageElements}
      </div>
    </div>
  )

  useEffect(() => {
    const currentData = {}
    document.querySelectorAll('.product-input').forEach((element) => {
      if (parseInt(element.value)) {
        currentData[element.name.split('!')[0]] = [parseInt(element.value), element.name.split('!')[1]]
      }
    })
    setFormData(currentData)
    try {
      document.querySelectorAll('#navigation h3').forEach((element) => {
        element.classList.remove('active')
      })
      document.querySelectorAll('#pages .page').forEach((element) => {
        if (element.getAttribute('data-page') < currentPage) {
          element.classList.add('left')
          element.classList.remove('right')
        } else if (element.getAttribute('data-page') > currentPage) {
          element.classList.add('right')
          element.classList.remove('left')
        } else {
          element.classList.remove('left')
          element.classList.remove('right')
        }
      })
      document.querySelector(`#navigation h3[data-page="${currentPage}"]`).classList.add('active')
      document.querySelector('#pages').style = 'height: calc(' + (document.querySelector('#quotation').getBoundingClientRect()['height'] - document.querySelector('#navigation').getBoundingClientRect()['height'] - document.querySelector('#quotation > h1').getBoundingClientRect()['height'] - document.querySelector('#nav-buttons').getBoundingClientRect()['height']) + 'px - 14vh)'
    } catch {

    }
  }, [currentPage, quotationForm])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('')
      }, 5000)
    }
  }, [errorMessage])

  return (
    <div id='main-content'>
      <div id='quotation-container'>
        <div id='quotation-errors' className={errorMessage ? 'active' : ''}>
          <h3>{errorMessage}</h3>
        </div>
        {quotationForm ? <div id='quotation'>
          <h1>{t('quotation.title')}</h1>
          <div id='navigation'>
            {navElements}
          </div>
          <div id='pages'>
            {pageElements}
          </div>
          <div id="nav-buttons">
            {currentPage !== 0 ?
              <Button label={t('quotation.prev-page')} f={() => {
                setCurrentPage(currentPage - 1)
              }} /> : <div style={{ width: '100%' }}></div>}
            {currentPage === navElements.length - 1 ?
              <Button label={t('quotation.submit')} f={() => {
                const data = {
                  captcha: captchaToken,
                  products: {},
                  contact: {
                    name: document.querySelector('#contact-name').value,
                    email: document.querySelector('#contact-email').value,
                    phone: document.querySelector('#contact-phone').value,
                    detail: document.querySelector('#contact-detail').innerHTML
                  }
                }
                for (const [quantity, id] of Object.values(formData)) {
                  data.products[id] = quantity
                }
                axios.post('/api/quotation', data, {
                  baseURL: '',
                  headers: {
                  }
                }).then((response) => {
                  navigate('/quotation/view?id=' + response.data.id)
                }).catch((err) => {
                  setErrorMessage(err.response.data.errors)
                })
              }} />
              :
              <Button label={t('quotation.next-page')} f={() => {
                setCurrentPage(currentPage + 1)
              }} />}
          </div>
        </div> : null}
      </div>
      <GoogleReCaptcha onVerify={setCaptchaToken} refreshReCaptcha={currentPage} />
    </div >
  )
}

function InputItem({ id, name, mp, up, category }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('products')

  return (
    <>
      <h6>{t(category + '.' + name)}</h6>
      <input min={0} name={category + '.' + name + '!' + id} className="product-input" onBlur={() => { setOpen(false) }} onFocus={() => { setOpen(true) }} id={`product-${id}`} type='number' defaultValue={0} />
      <div className={open ? "input-item-body active" : "input-item-body"}>
        {parseInt(up) ? <h3>{'$' + up + ' ' + t('translation:quotation.installation-fee')}</h3> : null}
        {parseInt(mp) ? <h3>{'$' + mp + t('translation:quotation.per-month')}</h3> : null}
        <p>{t(category + '.' + name + '-desc')}</p>
      </div>
    </>
  )
}

export default Quotation
