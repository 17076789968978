import React, { useEffect, useState } from 'react'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from "react-router-dom";
import './css/Header.css'
import { logout } from '../Auth'
import Button from './Button'
import './css/Burger.css'

function Burger(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'nav-bar' })
  const store = props['store']
  const [loggedIn, setLoggedIn] = props['loggedIn']
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === 'language') {
        setOpen(false)
      }
    }
    )
  }, [])

  return (
    <div id='burger-container'>
      <div id='burger-button' onClick={() => { setOpen(!isOpen) }}>
        <div className='line'>
        </div>
        <div className='line'>
        </div>
        <div className='line'>
        </div>
      </div>
      {isOpen ?
        <>
          <div id='burger-overlay' onClick={() => {
            setOpen(false)
          }}>
          </div>
          <div id='burger-menu'>
            <div id='burger-items'>
              <div id='nav-links'>
                <Link onClick={() => { setOpen(!isOpen) }} to='/'>{t('home')}</Link>
                <Link onClick={() => { setOpen(!isOpen) }} to='/services'>{t('services')}</Link>
                <Link onClick={() => { setOpen(!isOpen) }} to='/partners'>{t('partners')}</Link>
                <Link onClick={() => { setOpen(!isOpen) }} to='/contact'>{t('contact')}</Link>
              </div>
              {loggedIn ? (window.location.pathname.includes('internal') ? <Button id={null} f={() => { logout(); setLoggedIn(false); navigate('/'); setOpen(!isOpen) }} label={t('logout')} /> : <Button id={null} f={() => { navigate('/internal'); setOpen(!isOpen) }} label={t('internal')} />) : <Button id={null} f={() => { navigate('/login'); setOpen(!isOpen) }} label={t('login')} />}
              <LanguageSwitcher />
            </div>
            <Link to='/privacy' onClick={() => {
              setOpen(false)
            }}>{t('privacy-policy', { keyPrefix: 'privacy-policy' })}</Link>
          </div>
        </> : null}
    </div>
  )
}

export default Burger
