import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './css/Users.css'
import Button from '../components/Button'
import Switch from '../components/Switch'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

function NewUser(props) {
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [canSubmit, setCanSubmit] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  const formSubmit = (e) => {
    e.preventDefault()
    if (canSubmit) {
      var data = {}
      data.username = document.querySelector('#username').value.toLowerCase()
      data.password = document.querySelector('#password').value
      data.first_name = document.querySelector('#first_name').value
      data.last_name = document.querySelector('#last_name').value
      data.email = document.querySelector('#email').value
      data.is_staff = document.querySelector('#is_staff').getAttribute('value')

      console.log(data)

      setError(false)
      setMessage('Submitting...')
      axios.post('/api/users', data, {
        baseURL: '/',
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('accessToken'),
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then((response) => {
        setMessage('User created.')
      }).catch((err) => {
        setMessage(err.response.data.errors)
        setError(true)
      })
    } else {
      setError(true)
      setMessage(t('fill-all-fields'))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setError(false)
      setMessage('')
    }, 7000)
  }, [message])

  const handleInput = (e) => {
    if (!e.target.value) {
      e.target.classList.add('error')
      setCanSubmit(false)
    } else {
      e.target.classList.remove('error')
      setCanSubmit(true)
    }
  }

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal/users') }} label={'< ' + t('back')} />
      <div id='users-container'>
        <div id='new-user'>
          <h1 className={error ? 'error' : ''}>{message ? message : t('create-user-message')}</h1>
          <form id='new-user-form' method='post' onSubmit={formSubmit}>
            <div>
              <label htmlFor='first_name'>{t('first_name')}:</label>
              <input id='first_name' type='text' onChange={handleInput} />
            </div>
            <div>
              <label htmlFor='last_name'>{t('last_name')}:</label>
              <input id='last_name' type='text' onChange={handleInput} />
            </div>
            <div>
              <label htmlFor='username'>{t('username')}:</label>
              <input id='username' type='text' onChange={handleInput} />
            </div>
            <div>
              <label htmlFor='email'>{t('email')}:</label>
              <input id='email' type='email' onChange={handleInput} />
            </div>
            <div>
              <label htmlFor='password'>{t('password')}:</label>
              <input id='password' type='password' onChange={handleInput} />
            </div>
            <div>
              <label htmlFor='confirm_password'>{t('confirm_password')}:</label>
              <input id='confirm_password' type='password' onChange={(e) => {
                const password = document.querySelector('input#password').value
                if (e.target.value !== password) {
                  e.target.classList.add('error')
                  setCanSubmit(false)
                } else {
                  e.target.classList.remove('error')
                  setCanSubmit(true)
                }
              }} />
            </div>
            <div>
              <label htmlFor='is_staff'>{t('admins')}:</label>
              <Switch id='is_staff' val1='true' val2='false' />
            </div>
            <input type='hidden' name='csrfmiddlewaretoken' value={Cookies.get('csrftoken')} />
            <div>
              <button type="submit" form="new-user-form" value="Submit">{t('confirm')}</button>
            </div>
          </form>
        </div>
      </div>
    </div >
  )
}

export default NewUser
