import React, { useState } from 'react'
import './css/Switch.css'

function Switch(props) {
  const val1 = props['val1']
  const val2 = props['val2']
  const [value, setValue] = useState(val2)

  const flip = () => {
    if (value === val1) {
      setValue(val2)
    } else {
      setValue(val1)
    }
  }

  return (
    <div id={props['id']} className='switch' value={value} onClick={flip}>
      <div className={value === val1 ? 'cover' : 'cover active'}>
      </div>
      <h3>{val1}</h3>
      <h3>{val2}</h3>
    </div>
  )
}


export default Switch
