import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './css/Users.css'
import Button from '../components/Button'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Popup from '../components/Popup'
import { isAuthenticated } from '../Auth'
import makeID from '../MakeID'

function Users(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [data, setData] = useState(null)
  const [editingUser, setEditingUser] = useState('')
  const [changingPassword, setChangingPassword] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingUser, setDeletingUser] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    axios.get('/api/users', {
      headers: {
        'Authorization': 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setData(response.data)
    }).catch((err) => {
      if ((err.response.status === 401)) {
        window.location.reload(false)
      } else {
        console.log(err)
      }
    })
  }, [])

  const passwordChange = (e) => {
    e.preventDefault()
    const password = document.querySelector('#change-password-form div #password').value
    if (password == '') {
      setPasswordError(true)
      setPasswordMessage(t('password-empty'))
      return
    }
    if (password !== document.querySelector('#change-password-form div #confirm_password').value) {
      setPasswordError(true)
      setPasswordMessage(t('password-match'))
      return
    }
    setPasswordError(false)
    setPasswordMessage('Submitting...')
    axios.post(
      '/api/users/change_password',
      {
        'user': editingUser,
        'password': password
      },
      {
        baseURL: '/',
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('accessToken')
        }
      }
    ).then((response) => {
      setPasswordMessage(t('password-success-user'))
      setTimeout(() => {
        setEditingUser('')
        setChangingPassword(false)
      }, 1000)
    }).catch((err) => {
      setPasswordError(true)
      setPasswordMessage(err.response.data.errors)
    })
  }
  useEffect(() => {
    setTimeout(() => {
      setPasswordMessage('')
      setPasswordError(false)
    }, 7000)
  }, [passwordMessage])

  useEffect(() => {
    setPasswordMessage('')
    setPasswordError(false)
  }, [changingPassword])

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='users-container'>
        {data ? <UsersDisplay data={data} editingUser={[editingUser, setEditingUser]} changingPassword={[changingPassword, setChangingPassword]} deletingUser={[deletingUser, setDeletingUser]} isDeleting={[isDeleting, setIsDeleting]} /> : null}
        {changingPassword ? <Popup posX={0} posY={0} state={changingPassword} setState={setChangingPassword} content={(
          <div>
            <form style={{ width: '40vw' }} id='change-password-form' method='post' onSubmit={passwordChange}>
              <h1 style={{ background: (passwordError ? 'red' : 'white'), color: (passwordError ? 'white' : 'black'), margin: '1vh 0', padding: '1vh', 'font-size': '22px', 'white-space': 'pre-line' }}>{passwordMessage ? passwordMessage : t('change-password') + ` (${editingUser})`}</h1>
              <div>
                <label htmlFor='password'>{t('password')}:</label>
                <input id='password' type='password' />
              </div>
              <div>
                <label htmlFor='confirm_password'>{t('confirm_password')}:</label>
                <input id='confirm_password' type='password' />
              </div>
              <button type='submit' form='change-password-form'>{t('confirm')}</button>
            </form>
          </div>
        )} /> : null}
        {isDeleting ? <Popup posX={0} posY={0} state={isDeleting} setState={setIsDeleting} content={(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '3vh' }}>
            <h1 style={{ fontSize: '24px', margin: '0' }}>{t('confirm-delete')}<br /><span style={{ fontSize: '28px', textDecoration: 'underline', fontStyle: 'italic' }}>{document.querySelector('#entry-' + deletingUser + ' .short h2').innerHTML}</span>?</h1>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1vh', width: '100%', marginTop: '3vh' }}>
              <Button label={t('confirm')} f={() => {
                axios.delete('/api/users', {
                  params: {
                    id: deletingUser
                  },
                  headers: {
                    Authorization: 'Bearer ' + Cookies.get('accessToken')
                  }
                }).then((response) => {
                  window.location.reload(false)
                }).catch((err) => {
                  console.log(err)
                })
              }} />
              <Button label={t('cancel')} f={() => {
                setIsDeleting(false)
                setDeletingUser('')
              }} />
            </div>
          </div>
        )} /> : null}
      </div>
    </div>
  )
}

function UsersDisplay(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const data = props['data']
  var userElements = []
  var adminElements = []
  const navigate = useNavigate()

  for (const user of data) {
    if (!user.is_staff) {
      userElements.push(
        <Entry user={user} changingPassword={props['changingPassword']} editingUser={props['editingUser']} deletingUser={props['deletingUser']} isDeleting={props['isDeleting']} />
      )
    } else {
      adminElements.push(
        <Entry user={user} changingPassword={props['changingPassword']} editingUser={props['editingUser']} deletingUser={props['deletingUser']} isDeleting={props['isDeleting']} />
      )
    }
  }

  return (
    <div id='users'>
      <div id='users-action-bar'>
        <Button label={'+ ' + t('new-user')} f={() => {
          navigate('/internal/users/new')
        }} />
      </div>
      <div id='users-list'>
        <h1>{t('admins')}</h1>
        {adminElements}
        <h1>{t('users')}</h1>
        {userElements}
      </div>
    </div>
  )
}


function Entry(props) {
  const user = props['user']
  const [changingPassword, setChangingPassword] = props['changingPassword']
  const [editingUser, setEditingUser] = props['editingUser']
  const [deletingUser, setDeletingUser] = props['deletingUser']
  const [isDeleting, setIsDeleting] = props['isDeleting']
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [changingDetails, setChangingDetails] = useState(false)

  const submitEdits = (e) => {
    const data = {
      first_name: document.querySelector('#first_name-' + user.id).value || user.first_name,
      last_name: document.querySelector('#last_name-' + user.id).value || user.last_name,
      username: document.querySelector('#username-' + user.id).value.toLowerCase() || user.username,
      email: document.querySelector('#email-' + user.id).value || user.email,
    }
    axios.put('/api/users', data, {
      baseURL: '/',
      params: {
        id: user.id
      },
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      window.location.reload(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div id={'entry-' + user.id} className='entry'>
      <div className='short' onClick={() => {
        setOpen(!open)
      }}>
        <div className={open ? 'active arrow' : 'arrow'} >
        </div>
        <h2>{user.username}</h2>
        <h2>{user.first_name + ' ' + user.last_name + (
          sessionStorage.getItem('userData.username') === user.username ? ` (${t('you')})` : ''
        )}</h2>
        <h2>{(new Date(user.date_joined)).toLocaleString()}</h2>
      </div>
      <div className={open ? 'body' : 'body hidden'}>
        <h3 className='label'>{t('first_name')}</h3>
        {changingDetails ? <input id={'first_name-' + user.id} type='text' defaultValue={user.first_name} /> : <h3>{user.first_name}</h3>}
        <h3 className='label'>{t('last_name')}</h3>
        {changingDetails ? <input id={'last_name-' + user.id} type='text' defaultValue={user.last_name} /> : <h3>{user.last_name}</h3>}
        <h3 className='label'>{t('username')}</h3>
        {changingDetails ? <input id={'username-' + user.id} type='text' defaultValue={user.username} /> : <h3>{user.username}</h3>}
        <h3 className='label'>{t('email')}</h3>
        {changingDetails ? <input id={'email-' + user.id} type='text' defaultValue={user.email} /> : <h3>{user.email}</h3>}
        <h3 className='label'>{t('date_joined')}</h3>
        <h3>{(new Date(user.date_joined)).toLocaleString()}</h3>
        {sessionStorage.getItem('userData.username') !== user.username ?
          (
            <div style={{ gridColumn: '1 / 3', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1vh', padding: '1vh 0' }}>
              {!changingDetails ?
                <>
                  <Button label={user['is_staff'] ? '↓ ' + t('demote-staff') : '↑ ' + t('promote-staff')} f={() => {
                    axios.put('/api/users/staff', {}, {
                      baseURL: '',
                      params: {
                        id: user.id
                      },
                      headers: {
                        Authorization: 'Bearer ' + Cookies.get('accessToken')
                      }
                    }).then((response) => {
                      window.location.reload(false)
                    })
                  }} />
                  <Button label={t('change-details')} f={() => {
                    setChangingDetails(true)
                    setEditingUser(user.username)
                  }} />
                  <Button label={t('change-password')} f={() => {
                    setEditingUser(user.username)
                    if (!changingPassword) setChangingPassword(true)
                  }} />
                </> :
                <>
                  <Button label={t('confirm')} f={() => {
                    submitEdits()
                  }} />
                  <Button label={t('cancel')} f={() => {
                    setChangingDetails(false)
                    setEditingUser('')
                  }} />
                </>}
            </div>) : null}
        {(sessionStorage.getItem('userData.username') !== user.username) && (!editingUser) ? <div className='delete-user'>
          <Button label={t('delete').toUpperCase()} f={() => {
            setIsDeleting(true)
            setDeletingUser(user.id)
          }} />
        </div> : null}
      </div>
    </div >
  )
}

export default Users
