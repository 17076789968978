import React from "react";

import './css/Footer.css'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation()
  return (
    <footer>
      <div id="footer">
        <div id='footer-container'>
          <div id='footer-section-left'>
            <h6>Ngai Chiu Information Technology Co. LTD</h6>
            <br />
            <Link to='/contact'>{t('contact.form-title')}</Link>
          </div>
          <div id='footer-section-right'>
            <Link to='/privacy'>{t('privacy-policy.privacy-policy')}</Link>
            <br />
            <h6>This site is protected by reCAPTCHA and the <br /> Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.</h6>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
