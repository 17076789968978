import React, { useLayoutEffect } from 'react'

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../Auth'

import Home from '../pages/Home'
import Services from '../pages/Services'
import Contact from '../pages/Contact'
import Partners from '../pages/Partners'
import Login from '../pages/Login'
import Internal from '../pages/Internal'
import Translations from '../pages/Translations'
import Users from '../pages/Users'
import NewUser from '../pages/NewUser'
import ToolManagement from '../pages/ToolManagement'
import QrCode from '../pages/QrCode'
import Contacts from '../pages/Contacts'
import { ParallaxProvider } from 'react-scroll-parallax'
import EditPartners from '../pages/EditPartners'
import EditNotice from '../pages/EditNotice'
import Admin from '../pages/Admin'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import SendEmail from '../pages/SendEmail'
import Quotation from '../pages/Quotation'
import ProductManagement from '../pages/ProductManagement'
import QuotationViewer from '../pages/QuotationViewer'
import VerifyQuotation from '../pages/VerifyQuotation'


function Main(props) {
  const store = props['store']
  const navigate = useNavigate()
  const location = useLocation()

  useLayoutEffect(() => {
    if (window.location.pathname.includes('internal')) {
      if (!isAuthenticated()) {
        navigate('/login?dest=' + window.location.pathname)
      }
    }
  }, [location])


  return (
    <ParallaxProvider>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/quotation' element={<Quotation />} />
        <Route path='/quotation/view' element={<QuotationViewer />} />
        <Route path='/quotation/verify' element={<VerifyQuotation />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/login' element={<Login store={store} />} />
        {/* INTERNAL PAGES */}
        <Route path='/internal' element={<Internal store={store} />} />
        <Route path='/internal/translations' element={<Translations store={store} />} />
        <Route path='/internal/notice-management' element={<EditNotice />} />
        <Route path='/internal/users' element={<Users store={store} />} />
        <Route path='/internal/users/new' element={<NewUser store={store} />} />
        <Route path='/internal/tool-management' element={<ToolManagement store={store} />} />
        <Route path='/internal/partners' element={<EditPartners />} />
        <Route path='internal/send_email' element={<SendEmail />} />
        <Route path='/internal/qr_code' element={<QrCode store={store} />} />
        <Route path='/internal/contacts' element={<Contacts store={store} />} />
        <Route path='/internal/products' element={<ProductManagement />} />
        <Route path='/internal/admin' element={<Admin />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
      </Routes>
    </ParallaxProvider>
  )
}


export default Main
