import React, { useEffect, useState } from "react";
import './css/EditPartners.css'
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import blobToBase64 from "../blobToBase64";
import Cookies from 'js-cookie'
import { isAuthenticated } from "../Auth";

function EditPartners(props) {
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [data, setData] = useState([])
  const [creating, setCreating] = useState(false)

  var partnerElements = []

  useEffect(() => {
    if (!isAuthenticated()) {
      window.location.reload(false)
    }
    axios.get('/api/partners', {
      baseUrl: '/',
    }).then((response) => {
      setData(response.data)
    })
  }, [])

  data.forEach((item) => {
    partnerElements.push(
      <Entry name={item.name} link={item.link} socials={item.socials} description={item.description} logo={item.logo} id={item.id} />
    )
  })

  const create = () => {
    const form = new FormData()
    form.append('name', document.querySelector('#name-new').value)
    form.append('link', document.querySelector('#link-new').value)
    form.append('socials', document.querySelector('#socials-new').value)
    form.append('description', document.querySelector('#description-new').value)
    form.append('logo', document.querySelector('#logo-new').files[0], document.querySelector('#name-new').value.replaceAll(/[^\x00-\x7f]/g, '') + '.' + document.querySelector('#logo-new').files[0].type.split('/')[1])

    axios.post('/api/partners/edit', form, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      window.location.reload(false)
    })
  }

  return (
    <div id="main-content">
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id="edit-partners-container">
        <div id="edit-partners">
          <div style={{ height: '7vh', width: '100%', borderBottom: '3px solid var(--orange)' }}>
            <Button label={'+ ' + t('new-partner')} f={() => { setCreating(true); setTimeout(() => { document.querySelector('#name-new').focus() }, 10) }} />
          </div>
          <div id="edit-partners-grid">
            <h2>{t('name')}</h2>
            <h2>{t('link')}</h2>
            <h2>{t('description')}</h2>
            <h2>{t('socials')}</h2>
            <h2>{t('logo')}</h2>
            <h2>{t('actions')}</h2>
            {partnerElements}
            {creating ?
              <>
                <input id={'name-new'} type='text' defaultValue='' />
                <input id={'link-new'} type='text' defaultValue='https://' />
                <input id={'description-new'} type='text' defaultValue='' />
                <input id={'socials-new'} type='text' defaultValue='https://' />
                <input id={'logo-new'} type='file' />
                <div className="buttons">
                  <Button f={create} label={t('confirm')} />
                  <Button f={() => {
                    setCreating(false)
                  }} label={t('cancel')} />
                </div>
              </>
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

function Entry(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [editing, setEditing] = useState(false)

  const submitEdits = () => {
    try {
      const form = new FormData()
      form.append('id', props.id)
      form.append('name', document.querySelector('#name-' + props.id).value || props.name)
      form.append('link', document.querySelector('#link-' + props.id).value || props.link)
      form.append('socials', document.querySelector('#socials-' + props.id).value || props.socials)
      form.append('description', document.querySelector('#description-' + props.id).value || props.description)
      if (document.querySelector('#logo-' + props.id).files.length > 0) {
        form.append('logo', document.querySelector('#logo-' + props.id).files[0], document.querySelector('#name-' + props.id).value.replaceAll(/[^\x00-\x7f]/g, '') + '.' + document.querySelector('#logo-' + props.id).files[0].type.split('/')[1])
      }

      axios.put('/api/partners/edit', form, {
        baseURL: '/',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('accessToken')
        }
      }).then((response) => {
        window.location.reload(false)
      })
    } catch {
      return
    }

  }

  const deletePartner = () => {
    axios.delete('/api/partners/edit', {
      baseURL: '/',
      params: {
        id: props.id
      },
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      window.location.reload(false)
    })
  }

  return (
    <>
      {editing ?
        <>
          <input id={'name-' + props.id} type='text' defaultValue={props.name} />
          <input id={'link-' + props.id} type='text' defaultValue={props.link} />
          <input id={'description-' + props.id} type='text' defaultValue={props.description} />
          <input id={'socials-' + props.id} type='text' defaultValue={props.socials} />
          <input id={'logo-' + props.id} type='file' />
          <div className="buttons">
            <Button f={submitEdits} label={t('save')} />
            <Button f={() => {
              setEditing(false)
            }} label={t('cancel')} />
          </div>
        </> :
        <>
          <h3>{props.name}</h3>
          <h3>{props.link}</h3>
          <h3>{props.description}</h3>
          <h3>{props.socials}</h3>
          <h3>{props.logo}</h3>
          <div className="buttons">
            <Button f={() => {
              setEditing(true)
            }} label={t('edit')} />
            <Button f={deletePartner} label={t('delete')} />
          </div>
        </>
      }
    </>
  )
}

export default EditPartners
