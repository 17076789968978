import React, { useState, useEffect } from 'react'
import './css/MiniSlideshow.css'
import makeID from '../MakeID'

function MiniSlideshow(props) {
  var pics = []
  const id = makeID(6)
  props['pics'].forEach((pic) => {
    pics.push(<img key={pic} src={pic} alt={pic} className='mini-slideshow-img' />)
  })

  const [currentPic, setCurrentPic] = useState(0)
  useEffect(() => {
    var i = 0
    setInterval(() => {
      i = (i + 1) % 4
      setCurrentPic(i)
    }, 2000)
  }, [])

  useEffect(() => {
    const elements = document.querySelectorAll(`#${id} .mini-slideshow-img`)
    for (let x = 0; x < pics.length; x++) {
      if (x === currentPic) {
        elements[x].style = 'opacity: 100%'
      } else {
        elements[x].style = 'opacity: 0%'
      }
    }
  }, [currentPic])

  return (
    <div id={id} className='mini-slideshow'>
      {pics}
    </div>
  )
}

export default MiniSlideshow
