import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import "./i18n"


const root = ReactDOM.createRoot(document.getElementById("root"))

const meta = {
  title: 'NCC Technology',
  description: 'Ngai Chiu Information',
  meta: {
    charset: 'utf-8'
  }
}

root.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
))
