import React from 'react'
import { useTranslation } from 'react-i18next'
import './css/Services.css'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'

function Services(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'services' })
  const navigate = useNavigate()

  return (
    <div id='main-content'>
      <div id='services-container'>
        <div className='section'>
          <h1>Make It Easy</h1>
          <h4 id='quotation-button' onClick={() => {
            navigate('/quotation')
          }}>{t('get-quotation')}</h4>
          <h5>{t('find-quotation')}</h5>
          <input pattern='/^[0-9]*$/ ' placeholder='######' maxLength={6} id='search-quotation' type='text' onKeyPress={(event) => {
            if (event.key === 'Enter') document.querySelector('#find-quotation-button').click()
          }} />
          <Button id={'find-quotation-button'} label={t('search')} f={() => {
            const id = document.querySelector('#search-quotation').value.replace(/\D/g, '')
            if (id.length < 6) return
            navigate('/quotation/view?id=' + id)
          }} />
        </div>
      </div>
    </div>
  )
}

export default Services
