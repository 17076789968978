import React from "react"
import './css/HoverMessage.css'

function HoverMessage(props) {

  return (
    <div className="hover-message">
      <p className={props['state'] ? "hover-message-p active" : "hover-message-p"}>{props['message']}</p>
    </div>
  )
}

export default HoverMessage
