import React, { useEffect, useState } from "react";
import { isAuthenticated, login } from "../Auth";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import "./css/Login.css";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useGoogleLogin } from "@react-oauth/google";
import Button from "../components/Button";
import axios from "axios";

function Login(props) {
  const { t } = useTranslation("translation", { keyPrefix: "login" });

  const store = props["store"];
  const [loggedIn, setLoggedIn] = store["loggedIn"];
  const [searchParams, setSearchParams] = useSearchParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [loginAttempts, setLoginAttempts] = useState(0);
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(
          "/api/google_oauth?" + new URLSearchParams(codeResponse).toString(),
        )
        .then((response) => {
          if (!response.data.error) {
            localStorage.clear();
            setLoggedIn(true);
            Cookies.set("accessToken", response.data.access_token, {
              expires: 1 / 24,
            }); // accessToken expires after 1 hour
            Cookies.set("refreshToken", response.data.access_token, {
              expires: 7,
            }); // refreshToken expires after 1 week
            Cookies.set(
              "googleAccessToken",
              response.data.google_access_token,
              { expires: 1 / 24 },
            );
            Cookies.set(
              "googleRefreshToken",
              response.data.google_refresh_token,
            );
            navigate(searchParams.get("dest") || "/internal");
          } else {
            setErrorMessage(response.data.error);
          }
        });
    },
    flow: "auth-code",
  });

  useEffect(() => {
    sessionStorage.clear();
    if (isAuthenticated()) {
      navigate("/internal");
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    setLoginAttempts(loginAttempts + 1);
    setTimeout(() => {
      setErrorMessage("");
    }, 7000);
  }, [errorMessage]);

  const formSubmit = (e) => {
    if (!captchaToken) {
      return;
    }
    e.preventDefault();
    const form = new FormData(e.target);
    login(form.get("username"), form.get("password"), captchaToken).then(
      (data) => {
        if (data.error === null) {
          localStorage.clear();
          setLoggedIn(true);
          navigate(searchParams.get("dest") || "/internal");
        } else {
          setErrorMessage(data.error);
        }
      },
    );
  };

  return (
    <div id="main-content">
      <div id="login-container">
        <div id="socials-login">
          <div className="google-btn" onClick={() => googleLogin()}>
            <img src={require("../img/google.png")} />
            <label>{t("google")}</label>
          </div>
        </div>
        <form id="login-form" method="post" onSubmit={formSubmit}>
          {errorMessage ? (
            <p class="error">{errorMessage}</p>
          ) : (
            <p>{t("title")}</p>
          )}
          <div>
            <label htmlFor="username">{t("username")}:</label>
            <input id="username" type="text" name="username" />
          </div>
          <div>
            <label htmlFor="password">{t("password")}:</label>
            <input id="password" type="password" name="password" />
          </div>
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={Cookies.get("csrftoken")}
          />
          <button
            type="submit"
            form="login-form"
            value="Submit"
            style={{ opacity: captchaToken ? "1" : "0" }}
          >
            {t("login")}
          </button>
          <br />
        </form>
      </div>
      <GoogleReCaptcha
        onVerify={setCaptchaToken}
        refreshReCaptcha={loginAttempts}
      />
    </div>
  );
}

export default Login;
