import React, { useEffect, useState } from "react"
import './css/EditNotice.css'
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import axios from "axios"
import Cookies from 'js-cookie'
import StyleRenderer from '../components/StyleRenderer'

function EditNotice(props) {
  const [data, setData] = useState([])
  const noticeElements = []

  const [creating, setCreating] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })

  useEffect(() => {
    axios.get('/api/notice', {
      baseURL: '/',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setData(response.data)
    }).catch((err) => {
      window.location.reload(false)
    })
  }, [])

  data.forEach((item) => {
    noticeElements.push(
      <Entry title={item.title} id={item.id} content={item.content} date={item.date} last_edit={item.last_edit} />
    )
  })

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='edit-notices-container'>

        <div id='edit-notices'>
          <div id='notice-action-bar'>
            <Button label={'+ ' + t('new-notice')} f={() => {
              setCreating(true)
            }} />
          </div>
          <p id='notice-guide'>
            Styling Guide:
            <br />
            <br />
            ~[parameters]CONTENT~
            <br />
            <ul>
              <li><i>italic</i> : ~[i]CONTENT~</li>
              <li><b>bold</b> : ~[b]CONTENT~</li>
              <li><a href='https://ncc.hk'>link</a> : ~[a:url]CONTENT~</li>
              <li><span style={{ color: 'white', background: 'blue' }}>custom</span> : ~[$:"css"]CONTENT~</li>
            </ul>
            <br />
            Example:
            <br />
            <br />
            I ~[i]really~ love ~[b,a:https://mcdonalds.com]Mcdonald's~.
            <br />
            =
            <br />
            I <i>really</i> love <b><a href='https://mcdonalds.com' target="_blank">Mcdonald's</a></b>.
          </p>
          {creating ?
            <div className="notice-entry" style={{ animation: "verticalGrowIn 300ms ease" }}>
              <div className="buttons">
                <Button label={t('confirm')} f={() => {
                  const title = document.querySelector('#title-new').value
                  const content = document.querySelector('#content-new').innerHTML

                  if (!title || !content) {
                    return
                  }

                  const data = {
                    title: title,
                    content: content
                  }
                  axios.post('/api/notice/edit', data, {
                    baseURL: '/',
                    headers: {
                      Authorization: 'Bearer ' + Cookies.get('accessToken')
                    }
                  }).then((response) => {
                    window.location.reload(false)
                  })
                }} />
                <Button label={t('cancel')} f={() => {
                  setCreating(false)
                }} />
              </div>
              <input id={'title-new'} type="text" defaultValue='' placeholder={t('title')} />
              <p data-placeholder={t('content')} id={'content-new'} contentEditable='plaintext-only'></p>
            </div>
            : null}
          {noticeElements}
        </div>
      </div>
    </div>
  )
}

function Entry(props) {
  const [editing, setEditing] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })

  return (
    <div className="notice-entry">
      {!editing ?
        <>
          <div className="buttons">
            <Button label={t('edit')} f={() => {
              setEditing(true)
              setTimeout(() => {
                document.querySelector('#content-' + props.id).focus()
              }, 5)
            }} />
            <Button label={t('delete')} f={() => {
              axios.delete('/api/notice/edit', {
                baseURL: '/',
                params: {
                  id: props.id
                },
                headers: {
                  Authorization: 'Bearer ' + Cookies.get('accessToken')
                }
              }).then((response) => {
                window.location.reload(false)
              })
            }} />
          </div>
          <h3>{props.title}</h3>
          <StyleRenderer text={props.content} />
        </> :
        <>
          <div className="buttons">
            <Button label={t('save')} f={() => {
              const data = {
                id: props.id,
                title: document.querySelector('#title-' + props.id).value || props.title,
                content: document.querySelector('#content-' + props.id).innerHTML || props.content
              }
              axios.put('/api/notice/edit', data, {
                baseURL: '/',
                headers: {
                  Authorization: 'Bearer ' + Cookies.get('accessToken')
                }
              }).then((response) => {
                window.location.reload(false)
              })
            }} />
            <Button label={t('cancel')} f={() => {
              setEditing(false)
            }} />
          </div>
          <input id={'title-' + props.id} type="text" defaultValue={props.title} />
          <p id={'content-' + props.id} contentEditable='plaintext-only'>{props.content}</p>
        </>
      }
      <h6>{t('date') + ': ' + (new Date(props.date)).toLocaleString()}</h6>
      <h6>{t('last-edit') + ': ' + (new Date(props.last_edit)).toLocaleString()}</h6>
    </div>
  )
}

export default EditNotice
