import "./App.css";
import Main from "./components/Main";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import { isAuthenticated } from "./Auth";
import Footer from "./components/Footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const { i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);

  // stupid global state management
  const store = {
    loggedIn: [loggedIn, setLoggedIn],
  };

  useEffect(() => {
    document.title = "NCC Technology";
    if (localStorage.getItem("language")) {
      i18n.changeLanguage(localStorage.getItem("language"));
    } else {
      i18n.changeLanguage(
        (navigator.language || navigator.userLanguage).slice(0, 2),
      );
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId="872472276431-47rpj9fk8dnacsn1ho7677c27t02irj7.apps.googleusercontent.com">
      <GoogleReCaptchaProvider
        useEnterprise={true}
        reCaptchaKey="6Ldr-UsnAAAAAIPX39lZ6zNCCw5NaFc7y-REolYH"
      >
        <Header store={store} />
        <Main store={store} />
        <Footer />
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
