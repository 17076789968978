import React, { useEffect, useState } from "react"
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom"
import Button from "../components/Button"
import { useTranslation } from "react-i18next"
import './css/Contacts.css'
import axios from "axios"
import Popup from "../components/Popup"

function Contacts(props) {
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [data, setData] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingRequest, setDeletingRequest] = useState('')
  const contactElements = []

  useEffect(() => {
    axios.get('/api/contacts', {
      baseURL: '/',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setData(response.data)
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location.reload(false)
      } else {
        console.log(err)
      }
    })
  }, [])

  data.forEach((item) => {
    contactElements.push(
      <Entry data={item} isDeleting={[isDeleting, setIsDeleting]} deletingRequest={[deletingRequest, setDeletingRequest]} />
    )
  })

  return (
    <div id="main-content">
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='contacts-container'>
        {data ? <div id='contacts'>
          {isDeleting ? <Popup posX={0} posY={0} state={isDeleting} setState={setIsDeleting} content={(
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '3vh', maxWidth: '30vw' }}>
              <h1 style={{ fontSize: '24px', margin: '0' }}>{t('confirm-delete')}<span style={{ fontSize: '28px', textDecoration: 'underline', fontStyle: 'italic' }}>{document.querySelector('#entry-' + deletingRequest + ' .short h3').innerHTML}</span>{t('delete-contact-trail')}?</h1>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1vh', width: '100%', marginTop: '3vh' }}>
                <Button label={t('confirm')} f={() => {
                  axios.delete('/api/contacts', {
                    params: {
                      id: deletingRequest
                    },
                    headers: {
                      Authorization: 'Bearer ' + Cookies.get('accessToken')
                    }
                  }).then((response) => {
                    window.location.reload(false)
                  }).catch((err) => {
                    console.log(err)
                  })
                }} />
                <Button label={t('cancel')} f={() => {
                  setIsDeleting(false)
                  setDeletingRequest('')
                }} />
              </div>
            </div>
          )} /> : null}
          <div id='contacts-list'>
            {contactElements.length > 0 ? contactElements : <h1>None</h1>}
          </div>
        </div> : null}
      </div>
    </div>
  )
}

function Entry(props) {
  const data = props['data']
  const [isDeleting, setIsDeleting] = props['isDeleting']
  const [deletingRequest, setDeletingRequest] = props['deletingRequest']
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [open, setOpen] = useState(false)
  return (
    <div className='entry' id={'entry-' + data.id}>
      <div className='short' onClick={() => {
        setOpen(!open)
      }}>
        <div className={open ? 'active arrow' : 'arrow'} >
        </div>
        <h3>{data.name}</h3>
        <h3>{data.email}</h3>
        <h3>{data.phone_number}</h3>
        <div className="buttons">
          <Button id='delete-button' label={t('delete')} f={(e) => {
            setIsDeleting(true)
            setDeletingRequest(data.id)
            setTimeout(() => { setOpen(false) }, 1)
          }} />
        </div>
      </div>
      <div className={open ? "body" : "body hidden"}>
        <h2>{t('date')}:</h2>
        <p>{(new Date(data.date)).toLocaleString()}</p>
        <h2>{t('message')}:</h2>
        <p>{data.message}</p>
      </div>
    </div>
  )
}

export default Contacts
