import React, { useRef, useState } from "react"
import Button from "../components/Button"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import fileDownload from 'js-file-download'

import './css/QrCode.css'
import Cookies from "js-cookie"
import axios from "axios"
import blobToBase64 from "../blobToBase64"

function QrCode(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const navigate = useNavigate()
  const imgData = useRef('')
  const [imgUrl, setImgUrl] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [hovering, setHovering] = useState(false)

  const formSubmit = (e) => {
    e.preventDefault()
    const url = e.target.querySelector('#url').value
    const size = e.target.querySelector('#width').value + 'x' + e.target.querySelector('#height').value
    if (!url || !size) {
      setMessage(t('fill-all-fields'))
      setError(true)
    } else {
      axios.get('/api/qr_code', {
        baseURL: '/',
        responseType: 'blob',
        params: {
          url: url,
          size: size,
        },
        headers: {
          Authorization: 'Bearer ' + Cookies.get('accessToken')
        }
      }).then((response) => {
        imgData.current = response.data
        blobToBase64(response.data).then((r) => setImgUrl(r))
      })
    }
  }

  return (
    <div id="main-content">
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='qrcode-container'>
        <div id='qrcode'>
          <form id='qrcode-form' onSubmit={formSubmit}>
            <h1 className={error ? 'error' : ''}>{message ? message : t('qr-generator')}</h1>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 5fr', gap: '1vh' }}>
              <label htmlFor="url">URL:</label>
              <input id='url' type='text' />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr 2fr', gap: '1vh' }}>
              <label htmlFor="width">{t('image-width')}:</label>
              <input id='width' type='number' defaultValue='300' max='500' min='100' />
              <label htmlFor="height">{t('image-height')}:</label>
              <input id='height' type='number' defaultValue='300' max='500' min='100' />
            </div>
            <button type="submit" form="qrcode-form" value="Submit">{t('confirm')}</button>
          </form>
          <div id='qrcode-preview' onMouseEnter={() => {
            setHovering(true)
          }} onMouseLeave={() => {
            setHovering(false)
          }}>
            {imgUrl ? <img src={imgUrl} alt='qrcode' /> : null}
            {(hovering && imgData.current) ? <div id='qrcode-download' onClick={
              () => {
                fileDownload(imgData.current, document.querySelector('#url').value.replaceAll('.', '-') + '.png')
              }
            }><h1>{t('download')}</h1></div> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default QrCode
