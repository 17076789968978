import React from "react";

function StyleRenderer(props) {
  const text = props['text'].split('~')
  const elements = []

  for (let i = 0; i < text.length; i++) {
    var item = text[i]
    if (i % 2 == 0) {
      elements.push(item)
    } else {
      var i1 = item.search('\\[') + 1
      var i2 = item.search('\\]')
      var params = item.slice(i1, i2).split(',')
      var content = item.slice(i2 + 1, item.length)

      var element = content
      params.forEach((param) => {
        switch (param[0]) {
          case 'b':
            element = <b>{element}</b>
            break
            break
          case 'i':
            element = <i>{element}</i>
            break
          case 'a':
            element = <a target='_blank' href={param.slice(param.search(':') + 1, param.length)}>{element}</a>
            break
          case '$':
            element = <span STYLE={param.slice(param.search(':') + 2, param.length - 1)}>{element}</span>
            break
        }
      })
      elements.push(element)
    }
  }
  return <p>{elements}</p>
}

export default StyleRenderer
