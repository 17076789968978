import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

async function login(username, password, captcha) {
  try {
    const { data, status } = await axios.post(
      "api/token/",
      {
        username,
        password,
        captcha,
      },
      {
        headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      },
    );
    if (status === 200) {
      Cookies.set("accessToken", data.access, { expires: 1 / 24 }); // accessToken expires after 1 hour
      Cookies.set("refreshToken", data.refresh, { expires: 7 }); // refreshToken expires after 1 week
    }
    return { data, error: null, user: username };
  } catch (error) {
    console.log(error);
    return {
      data: null,
      error: error.response.data.detail || "Something went wrong",
    };
  }
}

function logout() {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("currentUser");
  localStorage.clear();
  sessionStorage.clear();
}

function tokenExpired(token) {
  try {
    if (token === null) {
      return false;
    }
    return jwt_decode(token).exp < Date.now() / 1000;
  } catch (err) {
    return true;
  }
}

function isAuthenticated() {
  try {
    if (!tokenExpired(Cookies.get("refreshToken"))) {
      if (tokenExpired(Cookies.get("accessToken"))) {
        renewAccessToken();
      }
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

async function renewAccessToken() {
  try {
    const response = await axios.post(
      "/api/token/refresh/",
      {
        refresh: Cookies.get("refreshToken"),
      },
      {
        baseURL: "/",
      },
    );
    Cookies.set("accessToken", response.data.access, { expires: 5 / 1440 });
    return true;
  } catch (err) {
    return false;
  }
}

export { login, logout, isAuthenticated, renewAccessToken };
