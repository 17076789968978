import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: require("./translations/site/en.json"),
    products: require("./translations/products/en.json")
  },
  zh: {
    translation: require("./translations/site/zh.json"),
    products: require("./translations/products/zh.json")
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: ["en", "zh"],
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
