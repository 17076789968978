import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "../components/Button"
import axios from "axios"
import Cookies from 'js-cookie'
import './css/ToolManagement.css'

function ToolManagement(props) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const navigate = useNavigate()

  const [toolsList, setToolsList] = useState([])
  const [groupsList, setGroupsList] = useState({})
  const toolTree = {
    'nogroup': []
  }
  const groupElements = [
    <GroupItem name={t('ungrouped')} id='nogroup' tools={toolTree['nogroup']} updateData={updateData} groupsList={groupsList} />
  ]

  const [addingGroup, setAddingGroup] = useState(false)

  function updateData() {
    axios.get('/api/tools', {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      setToolsList(response.data)
    }).catch((err) => {
      window.location.reload(false)
    })

    axios.get('/api/tool_groups', {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken')
      }
    }).then((response) => {
      const data = {}
      response.data.forEach((group) => {
        data[group.id] = group.name
      })
      setGroupsList(data)
    }).catch((err) => {
      window.location.reload(false)
    })
  }

  useEffect(() => {
    updateData()
  }, [])

  if (toolTree) {
    for (const group in groupsList) {
      toolTree[group] = []
      groupElements.push(
        <GroupItem name={groupsList[group]} id={group} tools={toolTree[group]} updateData={updateData} groupsList={groupsList} />
      )
    }
    for (const tool of toolsList) {
      try {
        toolTree[tool.group || 'nogroup'].push(tool)
      } catch {

      }
    }
  }

  return (
    <div id='main-content'>
      <Button id='back-button' f={() => { navigate('/internal') }} label={'< ' + t('back')} />
      <div id='tool-management-container'>
        <div id='tools'>
          <Button label={'+ ' + t('new-group').toUpperCase()} f={() => {
            setAddingGroup(true)
          }} />
          <div id='groups'>
            {addingGroup ?
              <div id='group-new' className="group">
                <div className="head">
                  <input type="text" placeholder={t('name')}></input>
                  <div className="buttons">
                    <Button label={t('confirm')} f={() => {
                      if (!document.querySelector('#group-new .head input').value) {
                        return
                      }
                      axios.post('/api/tool_groups/edit', {
                        name: document.querySelector('#group-new .head input').value
                      }, {
                        baseURL: '',
                        headers: {
                          Authorization: 'Bearer ' + Cookies.get('accessToken')
                        }
                      }).then((response) => {
                        setAddingGroup(false)
                        updateData()
                      })
                    }} />
                    <Button label={t('cancel')} f={() => {
                      setAddingGroup(false)
                    }} />
                  </div>
                </div>
              </div>
              : null}
            {groupElements}
          </div>
        </div>
      </div>
    </div>
  )
}

function GroupItem({ name, id, tools, updateData, groupsList }) {
  const toolElements = []
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })

  const [editing, setEditing] = useState(false)
  const [addingTool, setAddingTool] = useState(false)

  const [newToolBgColor, setNewToolBgColor] = useState('#ffffff')
  const [newToolFontColor, setNewToolFontColor] = useState('#000000')

  for (const tool of tools) {
    toolElements.push(
      <ToolItem id={tool.id} name={tool.label} link={tool.link} description={tool.description} fontColor={tool.font_color} bgColor={tool.bg_color} group={tool.group} updateData={updateData} groupsList={groupsList} />
    )
  }

  return (
    <div id={'group-' + id} className="group">
      <div className="head">
        {editing ?
          <>
            <input type="text" defaultValue={name}></input>
            <div className="buttons">
              <Button label={t('confirm')} f={() => {
                if (!document.querySelector('#group-' + id + ' .head input').value) {
                  return
                }
                axios.put('/api/tool_groups/edit', {
                  name: document.querySelector('#group-' + id + ' .head input').value,
                  id: id,
                }, {
                  baseURL: '',
                  headers: {
                    Authorization: 'Bearer ' + Cookies.get('accessToken')
                  }
                }).then((response) => {
                  setEditing(false)
                  updateData()
                }).catch((err) => {
                  console.log(err)
                  if (err.response.status === 401) {
                    window.location.reload(false)
                  }
                })
              }} />
              <Button label={t('cancel')} f={() => {
                setEditing(false)
              }} />
            </div>
          </> :
          <>
            <h1>{name}</h1>
            <div className="buttons">
              {id !== 'nogroup' ?
                <>
                  <Button label={t('edit')} f={() => {
                    setEditing(true)
                    setTimeout(() => {
                      document.querySelector('#group-' + id + ' .head input').focus()
                    }, 0)
                  }} />
                  <Button label={t('delete')} f={() => {
                    axios.delete('/api/tool_groups/edit', {
                      baseURL: '',
                      params: {
                        id: id
                      },
                      headers: {
                        Authorization: 'Bearer ' + Cookies.get('accessToken')
                      }
                    }).then((response) => {
                      updateData()
                    }).catch((err) => {
                      console.log(err)
                      if (err.response.status === 401) {
                        window.location.reload(false)
                      }
                    })
                  }} />
                </> : null}
              <Button label={'+ ' + t('new-tool')} f={() => {
                setAddingTool(true)
              }} />
            </div>
          </>}
      </div>
      <div className="body">
        {addingTool ?
          <div id='tool-new' className="tool" style={{ background: newToolBgColor }}>
            <div className="head">
              <input id='name-new' type='text' style={{
                color: newToolFontColor,
                borderColor: newToolFontColor,
              }} placeholder={t('label')} />
              <input id='link-new' type='text' style={{
                color: newToolFontColor,
                borderColor: newToolFontColor,
              }} defaultValue='https://' placeholder={t('link')} />
              <div className="buttons">
                <Button label={t('confirm')} f={() => {
                  axios.post('/api/tools/edit', {
                    label: document.querySelector('#name-new').value,
                    link: document.querySelector('#link-new').value,
                    description: document.querySelector('#desc-new').innerHTML,
                    bg_color: document.querySelector('#bg-new').value,
                    font_color: document.querySelector('#font-new').value,
                    group: id === 'nogroup' ? null : id
                    ,
                  }, {
                    baseURL: '',
                    headers: {
                      Authorization: 'Bearer ' + Cookies.get('accessToken')
                    }
                  }).then((response) => {
                    setAddingTool(false)
                    updateData()
                  }).catch((err) => {
                    console.log(err)
                    if (err.response.status === 401) {
                      window.location.reload(false)
                    }
                  })
                }} />
                <Button label={t('cancel')} f={() => {
                  setAddingTool(false)
                }} />
              </div>
            </div>
            <div className="body">
              <p id='desc-new' style={{
                color: newToolFontColor,
                borderColor: newToolFontColor,
              }} contentEditable='plaintext-only'></p>
              <div>
                <input id='bg-new' type='color' defaultValue='#ffffff' onChange={(e) => {
                  setNewToolBgColor(e.target.value)
                }} />
                <input id='font-new' type='color' defaultValue='#000000' onChange={(e) => {
                  setNewToolFontColor(e.target.value)
                }} />
              </div>
            </div>
          </div>
          : null}
        {toolElements.length > 0 ? toolElements : <h1>{t('none')}</h1>}
      </div>
    </div>
  )
}

function ToolItem({ id, name, link, description, bgColor, fontColor, group, updateData, groupsList }) {
  const { t } = useTranslation('translation', { keyPrefix: 'internal' })
  const [editing, setEditing] = useState(false)
  const [changingGroup, setChangingGroup] = useState(false)

  const [fontColorState, setFontColor] = useState(fontColor)
  const [bgColorState, setBgColor] = useState(bgColor)

  const groupSelections = []
  if (group) {
    groupSelections.push(
      <div style={{
        background: 'white',
        padding: '1vh',
        width: '100%',
        cursor: 'pointer'
      }} id={`tool-${id}-group-nogroup`} onClick={() => {
        axios.put('/api/tools/edit', {
          label: name,
          link: link,
          description: description,
          bg_color: bgColor,
          font_color: fontColor,
          group: null
        }, {
          baseURL: '',
          params: {
            id: id
          },
          headers: {
            Authorization: 'Bearer ' + Cookies.get('accessToken')
          }
        }).then((response) => {
          setChangingGroup(false)
          updateData()
        }).catch((err) => {
          console.log(err)
          if (err.response.status === 401) {
            window.location.reload(false)
          }
        })
      }}>
        {t('ungrouped')}
      </div>
    )
  }

  for (const groupItem in groupsList) {
    if (groupItem != group) {
      groupSelections.push(
        <div style={{
          background: 'white',
          padding: '1vh',
          width: '100%',
          cursor: 'pointer'
        }} id={`tool-${id}-group-${groupItem}`} onClick={() => {
          axios.put('/api/tools/edit', {
            label: name,
            link: link,
            description: description,
            bg_color: bgColor,
            font_color: fontColor,
            group: groupItem
          }, {
            baseURL: '',
            params: {
              id: id
            },
            headers: {
              Authorization: 'Bearer ' + Cookies.get('accessToken')
            }
          }).then((response) => {
            setChangingGroup(false)
            updateData()
          }).catch((err) => {
            console.log(err)
            if (err.response.status === 401) {
              window.location.reload(false)
            }
          })
        }}>
          {groupsList[groupItem]}
        </div>
      )
    }
  }

  return (
    <div id={'tool-' + id} className="tool" style={{ background: bgColorState }}>
      <div className="head">
        {editing ?
          <>
            <input style={{ color: fontColorState, border: `2px solid ${fontColorState}` }} id={'name-' + id} type='text' defaultValue={name} />
            <input style={{ color: fontColorState, border: `2px solid ${fontColorState}` }} id={'link-' + id} type='text' defaultValue={link} />
            <div className="buttons">
              <Button label={t('confirm')} f={() => {
                axios.put('/api/tools/edit', {
                  label: document.querySelector('#name-' + id).value || name,
                  link: document.querySelector('#link-' + id).value || link,
                  description: document.querySelector('#desc-' + id).innerHTML || description,
                  bg_color: bgColorState,
                  font_color: fontColorState,
                  group: group,
                }, {
                  params: {
                    id: id
                  },
                  baseURL: '',
                  headers: {
                    Authorization: 'Bearer ' + Cookies.get('accessToken')
                  }
                }).then((response) => {
                  setEditing(false)
                  updateData()
                }).catch((err) => {
                  console.log(err)
                  if (err.response.status === 401) {
                    window.location.reload(false)
                  }
                })
              }} />
              <Button label={t('cancel')} f={() => {
                setEditing(false)
                setBgColor(bgColor)
                setFontColor(fontColor)
              }} />
            </div>
          </> :
          <>
            <h3 style={{ color: fontColorState }}>{name}</h3>
            <a href={link} target='_blank' style={{ color: fontColorState }}>{link}</a>
            <div className="buttons">
              {changingGroup ?
                <div style={{ width: '100%', position: 'relative' }}>
                  <Button label={t('cancel')} f={() => {
                    setChangingGroup(false)
                  }} />
                  <div style={{ position: 'absolute', width: '100%' }}>
                    {groupSelections}
                  </div>
                </div>
                :
                <>
                  <Button label={t('move-group')} f={() => {
                    setChangingGroup(true)
                  }} />
                  <Button label={t('edit')} f={() => {
                    setEditing(true)
                  }} />
                  <Button label={t('delete')} f={() => {
                    axios.delete('/api/tools/edit', {
                      baseURL: '',
                      params: {
                        id: id
                      },
                      headers: {
                        Authorization: 'Bearer ' + Cookies.get('accessToken')
                      }
                    }).then((response) => {
                      updateData()
                    }).catch((err) => {
                      console.log(err)
                      if (err.response.status === 401) {
                        window.location.reload(false)
                      }
                    })
                  }} />
                </>}
            </div>
          </>}
      </div>
      <div className="body">
        {editing ?
          <>
            <p id={'desc-' + id} style={{ color: fontColorState, border: `2px solid ${fontColorState}` }} contentEditable='plaintext-only'>{description}</p>
            <div>
              <input id={'bg-' + id} type='color' defaultValue={bgColor} onChange={(e) => {
                setBgColor(e.target.value)
              }} />
              <input id={'font-' + id} type='color' defaultValue={fontColor} onChange={(e) => {
                setFontColor(e.target.value)
              }} />
            </div>
          </> :
          <>
            <p style={{ color: fontColorState }}>{description}</p>
            <div>
              <div className="color-display" style={{ background: bgColor }}></div>
              <div className="color-display" style={{ background: fontColor }}></div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default ToolManagement
