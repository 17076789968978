import axios from "axios"
import React, { useEffect, useState } from "react"
import blobToBase64 from "../blobToBase64"
import './css/Partners.css'
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"


function Partners(props) {
  const [data, setData] = useState([])
  const { t } = useTranslation('translation', { keyPrefix: 'partners' })
  var partnerElements = []

  for (let i = 0; i < data.length; i++) {
    var item = data[i]
    partnerElements.push(
      <Partner logo={item.logo} name={item.name} socials={item.socials} description={item.description} link={item.link} delay={i * 100} />
    )
  }

  useEffect(() => {
    axios.get('/api/partners', {
      baseURL: '/'
    }).then((response) => {
      setData(response.data)
    })
  }, [])

  return (
    <div id="main-content">
      <div id='partners-container'>
        <div id='partners'>
          <h1>{t('partners')}:</h1>
          <div id='partners-grid'>
            {partnerElements}
          </div>
        </div>
      </div>
    </div>
  )
}

function Partner(props) {
  const [image, setImage] = useState('')
  const { t } = useTranslation('translation', { keyPrefix: 'partners' })

  useEffect(() => {
    axios.get('/api/get_upload', {
      baseURL: '/',
      responseType: 'blob',
      params: {
        file: props.logo.slice(9),
      }
    }).then((response) => {
      blobToBase64(response.data).then((r) => setImage(r))
    })
  }, [])

  return (
    <div className="partner" style={{ animationDelay: props['delay'] + 'ms' }}>
      {image ? <a className="img" target="_blank" href={props.link}><img src={image} alt={props.name} /></a> : null}
      <h2>{props.name}</h2>
      <h4>{props.description}</h4>
      <a href={props.socials} target="_blank">{t('connect')}</a>
    </div>
  )
}

export default Partners
