import React, { useEffect, useRef, useState } from "react";
import './css/QuotationViewer.css'
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import fileDownload from "js-file-download";
import axios from "axios";
import Button from "../components/Button";
import blobToBase64 from "../blobToBase64";


function QuotationViewer(props) {
  const quotationPDFData = useRef('')
  const [quotationPDF, setQuotationPDF] = useState(null)
  const [quotationResult, setQuotationResult] = useState(null)
  const [attempted, setAttempted] = useState(false)
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const quotationID = searchParams.get('id')

  useEffect(() => {
    axios.get('/api/quotation/get', {
      params: {
        id: quotationID
      },
      baseURL: ''
    }).then((response) => {
      setAttempted(true)
      setQuotationResult(response.data)
      axios.get('/api/get_upload', {
        baseURL: '',
        responseType: 'blob',
        params: {
          file: response.data.file.slice(9)
        }
      }).then((response) => {
        quotationPDFData.current = response.data
        blobToBase64(response.data).then((r) => {
          setQuotationPDF(r)
        })
      })
    }).catch((err) => {
      setAttempted(true)
    })
  }, [])

  return (
    <div id='main-content'>
      <div id='quotation-result-container'>
        {quotationResult ? <div id='quotation-result'>
          <h2>{t('quotation.thanks')}</h2>
          <h3>{t('quotation.quotation-id')}: {quotationResult.id}</h3>
          <p>{t('quotation.result-message')}</p>
          <div className="buttons">
            <Button label={t('quotation.cancel-quotation')} f={() => {
              axios.delete('/api/quotation/cancel', {
                baseURL: '',
                params: {
                  id: quotationID
                }
              }).then((response) => {
                navigate('/')
              })
            }} />
            <Button label={t('quotation.download-quotation')} f={() => {
              fileDownload(quotationPDFData.current, 'ncchk-quotation-' + quotationResult.id + '.pdf')
            }} />
          </div>
          <div id='quotation-pdf'>
            <embed src={quotationPDF} width='100%' height='100%' type="application/pdf" />
          </div>
          <br />
        </div> : attempted ?
          <div id='quotation-result'>
            <div style={{ width: '100%' }}>
              <h2>{t('quotation.not-found')}</h2>
              <Link to='/services'>{t('internal.back')}</Link>
            </div>
          </div> :
          <div id='quotation-result'>
            <h2>{t('searching')}</h2>
          </div>
        }
      </div>
    </div>
  )
}

export default QuotationViewer
