import React, { useState } from 'react'
import './css/Popup.css'
import Draggable from 'react-draggable'

function Popup(props) {
  const state = props['state']
  const setState = props['setState']
  const content = props['content']
  const posX = props['posX']
  const posY = props['posY']

  return (
    <Draggable style={{ position: 'absolute' }} defaultPosition={{ x: posX, y: posY }}>
      <div className='popup'>
        <div className='cross' onClick={() => {
          setState(!state)
        }}>
        </div>
        <div className='content-container'>
          {content}
        </div>
      </div>
    </Draggable >
  )
}

export default Popup
